/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.bg-primary{
  background-color: #6C757D !important;
}

.bg-broad {
  background-color: #CC3179 !important;
}

.text-primary {
  color: #CC3179 !important;
}
